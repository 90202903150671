<template>
  <div>
    <v-data-table
      :items="dataList"
      :pagination.sync="pagination"
      :loading="isLoading"
      :total-items="total"
      :rows-per-page-items="[10, 25, 50, 100]"
      class="elevation-1"
      :headers="headers"
      :headers-length="4"
    >
      <template slot="items" slot-scope="props">
        <!--        <td>-->
        <!--          <router-link-->
        <!--            :to="`/moderation/user_list/user/${props.item.id}`"-->
        <!--            class="blue-grey&#45;&#45;text bold link-hover"-->
        <!--          >-->
        <!--            {{ props.item.username }}-->
        <!--          </router-link>-->
        <!--        </td>-->
        <td class="text-xs-left break">
          {{ props.item.id }}
          <!--          <span v-if="props.item.isNew" class="red&#45;&#45;text ml-3 bold">New!-->
          <!--          </span>-->
        </td>
        <td class="text-xs-left  break">
          {{ props.item.link }}
        </td>
        <td class="text-xs-left">
          {{ dateFormat(props.item.date) }}
        </td>
        <td
          class="text-xs-right"
        >
          <v-btn
            v-if="props.item.active"
            color="warning"
            class="ma-0"
            :loading="props.item.id === isLoadingUnlock"
            @click="openDisableModal(props.item.id, props.index)"
          >
            disable parser
          </v-btn>

          <v-btn
            v-else
            class="ma-0"
            color="success"
            :loading="props.item.id === isLoadingUnlock"
            @click.native="enableParser(props.item.id, props.index)"
          >
            enable parser
          </v-btn>
          <v-btn
            color="error"
            class="ma-0 ml-2"
            :loading="props.item.id === isDeleteLoading"
            @click="openDeleteModal(props.item.id, props.index)"
          >
            Delete
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        @input="refreshPagination"
      />
    </div>

    <disable-parser-modal
      v-model="disableModal"
      :parser-change-status="parserChangeStatus"
    />
    <DeleteParserModal
      v-model="deleteModal"
      :delete-parser="deleteParser"
    />
  </div>
</template>

<script>
import SavesPagination from "../../mixins/SavesPaginationMixin"
import {mapGetters} from "vuex"
import {getAllParsersRequest, parserChangeRequest, deleteParsedLink} from "../../api/parser"
import {fetchData} from "../../utils/response"
import moment from "moment"
import DisableParserModal from "./DisableParserModal"
import DeleteParserModal from "./DeleteParserModal"

export default {
  name: "EnableParserList",
  components: {
    DisableParserModal,
    DeleteParserModal,
  },
  mixins: [SavesPagination],
  data() {
    return {
      headers: [
        { text: "Id", sortable: false },
        { text: "Parsed Link", sortable: false },
        { text: "Date", sortable: false },
        { text: "", sortable: false },
      ],
      dataList: [],
      disableModal: false,
      deleteModal: false,
      isDeleteLoading: null,
      userId: null,
      itemIndex: null,
      deletedUser: [],
      dialog: false,
      editedIndex: -1,
      pagination: {},
      isLoadingUnlock: null,
      isLoading: false,
      total: 0,
      allUsersList: [],
      select: null,
      search: null,
      blockUserId: null,
    }
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"]),
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  created() {
    this.$bus.on("new-parser", this.refresh)
    // this.$bus.on("user-search-input", this.onSearchUser)
  },
  destroyed() {
    this.search = null
    this.$bus.off("new-parser")
    // this.$bus.off("user-search-input", this.onSearchUser)
  },
  methods: {
    openDeleteModal(id) {
      this.userId = id
      this.deleteModal = true
    },
    async deleteParser(){
      this.deleteModal = false
      this.isDeleteLoading = this.userId
      const params = {
        link_id: this.userId,
        resource: this.$route.params.name,
      }
      await deleteParsedLink(params)
          .then(() => {
            this.$notificationShow("The parser link is deleted", 3000)
            this.refresh()
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isDeleteLoading = null
          })
    },
    openDisableModal(id, index) {
      this.userId = id
      this.itemIndex = index
      this.disableModal = true
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        name: this.$route.params.name,
      }
      this.isLoading = true
      this.dataList = await getAllParsersRequest(params)
          .then(response => {
            this.total = response.data.meta.total
            return response
          })
          .then(fetchData)
          .then(data =>
              data.map(item => {
                return item
              })
          )
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoading = false
          })
    },
    enableParser(id, index){
      this.userId = id
      this.itemIndex = index
      this.parserChangeStatus(1)
    },
    parserChangeStatus(active = 0) {
      this.disableModal = false
      this.isLoadingUnlock = this.userId
      const params = {
        active: active,
        channel_id: this.userId,
        name: this.$route.params.name,
      }
      parserChangeRequest(params)
          .then(() => {
            active
                ? this.$notificationShow("Parser starts work successfully!", 5000)
                : this.$notificationShow("Parser is stopped", 5000)
            active ? this.dataList[this.itemIndex].active = 1 : this.dataList[this.itemIndex].active = 0
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoadingUnlock = null
            this.itemIndex = null
          })
    },
    // unlockedUser(id) {
    //   this.isLoadingUnlock = id
    //   userUnbanned(id)
    //       .then(() => {
    //         let index = this.allUsersList
    //             .map(item => {
    //               return item.id
    //             })
    //             .indexOf(id)
    //         this.allUsersList[index].banned = false
    //         // this.deletedUser.includes(id)
    //         //   ? this.deletedUser.splice(this.deletedUser.indexOf(id), 1)
    //         //   : false;
    //         this.$notificationShow("The user is successfully unlocked")
    //       })
    //       .catch(this.$notificationHandleResponseError)
    //       .finally(() => (this.isLoadingUnlock = null))
    // },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
          this.pagination.page,
          this.pagination.rowsPerPage
      )
    },
    // onSearchUser(searchUserValue) {
    //   this.search = searchUserValue
    //   this.refresh()
    // },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  },
}
</script>

<style scoped>
>>>.v-datatable.v-table thead tr:first-of-type {
  display: table-row;
}
.mail {
  max-width: 832px;
}
</style>
