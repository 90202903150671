import api from "./index"

/**
 * Create Advertising
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const createParserChannelRequest = (params, includes = []) => {
  return api
      .request(`admin/parser/${params.name}/channel`)
      .withParams(params)
      .withIncludes(includes)
      .post()
}

/**
 * Get all parsers
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getAllParsersRequest = (params, includes = []) => {
  return api
      .request(`admin/parser/${params.name}/channel`)
      .withParams(params)
      .withIncludes(includes)
      .get()
}

/**
 * parser on off
 *
 * @return {*}
 * @param params
 */
export const parserChangeRequest = params => {
  return api
      .request(`admin/parser/${params.name}/channel`)
      .withParams(params)
      .put()
}

/**
 * Delete parser link
 *
 * @param params
 *
 *
 * @return {*}
 */
export const deleteParsedLink = params => {
  return api
      .request(`admin/parser/${params.resource}/channel/${params.link_id}`)
      .withParams()
      .delete()
}

export const runParser = resource => {
  api.request(`admin/parser/${resource}/run`).post()
}