<template>
  <div class="pb-3">
    <div class="form-block">
      <div class="form-l">
        <div class="wrap">
          <v-text-field
            ref="editTitle"
            v-model="parserLink"
            :error-messages="err"
            :counter="maxInput"
            :rules="$route.params.name === 'telegram'? nameRules: nameRulesReddit"
            name="title"
            solo
            label="Parser Link"
            required
            @input="parsInput"
          />
        </div>
        <div class="wrap">
          <v-select
            v-model="selected"
            :items="countries"
            item-text="name"
            item-value="id"
            label="Select Country"
            return-object
            solo
            hide-details
          />
        </div>
      </div>
      <div>
        <v-btn
          :loading="isLoading"
          :disabled="disable"
          color="success"
          type="submit"
          @click="submit"
        >
          Enable Parser
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {createParserChannelRequest} from '../../api/parser'
import {mapActions, mapGetters} from "vuex"
// import { extend } from 'vee-validate'
//
// extend('positive', value => {
//   return value >= 0
// })

export default {
  name: "EnableParserTop",
  data: () => ({
    isLoading: false,
    maxInput: 150,
    parserLink: null,
    selected: { name: "Ukraine", id: 42 },
    disable: true,
    items: [],
    nameRules: [
      v => !!v || "Link is required",
      v => (v && v.length <= 150) || "Link may not be greater than 150 characters",
      v => (v && v.indexOf('https://t.me/') === 0 ) || "Please add correct telegram link"
    ],
    nameRulesReddit: [
      v => !!v || "Link is required",
      v => (v && v.length <= 150) || "Link may not be greater than 150 characters",
      v => (v && v.indexOf('https://www.reddit.com/') === 0 ) || "Please add correct reddit link"
    ],
    err: ''
  }),
  computed: {
    ...mapGetters({countries:'countries'}),
  },
  created() {
    if (!Object.keys(this.countries).length){
      this.getCountries()
    }
  },
  methods: {
    ...mapActions(["getCountries"]),
   parsInput() {
     !this.$refs.editTitle.validate() ? this.disable = true : this.disable = false
      if (this.parserLink && this.parserLink.length && this.parserLink.length > this.maxInput) {
        this.$nextTick(() => {
          this.parserLink = this.parserLink.slice(0, this.maxInput)
        })
      }
   },
    async submit() {
      const valid = await this.$refs.editTitle.validate()
      if(!valid) {
        return
      }
      this.createParser()
    },
    createParser(){
      this.isLoading = true
      const params = {
        country_id: this.selected.id,
        link: this.parserLink,
        name: this.$route.params.name,
      }

      createParserChannelRequest(params)
          .then(() => {
            this.$nextTick(() =>{
              this.parserLink = null
              this.err = ''
              this.$refs.editTitle.reset()
            })
            this.$notificationShow("Parser starts work successfully!", 5000)
            this.$bus.emit("new-parser")
          })
          .catch( (e) => {
            if (e.response.data.errors && e.response.data.errors.link) {
              this.$notificationHandleResponseError(e.response.data.errors.link)
            } else {
              this.$notificationHandleResponseError(e.response.data.message)
            }
          })
          .finally( () => {this.isLoading = false})
    },
    // async getsCountries () {
    //   this.items = await getAllCountries()
    //   .then((res)=> {
    //     console.log(res.data.data)
    //     return res.data.data
    //   })
    // }
  }
}
</script>

<style scoped>
.form-block{
  display: flex;
  justify-content: space-between;
  align-items: baseline;

}
.form-l{
  flex: 0 0 80%;
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.form-l > .wrap{
  flex: 0 0 50%;
  max-width: calc(50% - 32px);
}
>>> .v-btn{
  font-size: 18px;
}
.wrap{
  position: relative;
}


</style>