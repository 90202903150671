<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card>
        <v-layout align-center justify-space-between>
          <span class="headline"></span>

          <v-btn icon dark class="ma-0" @click.native="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-layout>

        <v-card-text>
          <span class="headline">{{ formTitle }}</span>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            No
          </v-btn>
          <v-btn color="success" @click.native="save">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "DeleteParserModal",
  props: {
    value: Boolean,
    userId: {
      type: [Number, String],
      default: null
    },
    deleteParser: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      formTitle: "Do you really want to delete this link from parser?",
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    show: function(isOpen) {
      if (isOpen) {
        document.querySelector("html").style.overflowY = "hidden"
      } else {
        document.querySelector("html").style.overflowY = "auto"
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    async save() {
      this.deleteParser()
    }
  }
}
</script>
