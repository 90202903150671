<template>
  <div>
    <enable-parser-top />
    <enable-parser-list />
  </div>
</template>

<script>
import EnableParserTop from '@/components/EnableParser/EnableParserTop'
import EnableParserList from '@/components/EnableParser/EnableParserList'
export default {
  name: "EnableParser",
  components: {
    EnableParserTop,
    EnableParserList,
  }
}
</script>

<style scoped>

</style>