<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card>
        <v-layout align-center justify-space-between>
          <span class="headline"></span>

          <v-btn icon dark class="ma-0" @click.native="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-layout>

        <v-card-text>
          <span class="headline">{{ formTitle }}</span>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            No
          </v-btn>
          <v-btn color="success" :loading="isLoading" @click.native="save">
            Yes, stop it
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { userBanned } from "../../api/user"
export default {
  name: "DisableParserModal",
  props: {
    value: Boolean,
    userId: {
      type: [Number, String],
      default: null
    },
    parserChangeStatus: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      formTitle: "Are you sure you want to stop this parser?",
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    show: function(isOpen) {
      if (isOpen) {
        document.querySelector("html").style.overflowY = "hidden"
      } else {
        document.querySelector("html").style.overflowY = "auto"
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    async save() {
      this.parserChangeStatus()
      // this.isLoading = true
      // await userBanned(this.userId, this.selectedReason.id, this.description)
      //   .then(response => {
      //     if (response && response.status === 200) {
      //       this.close()
      //       this.$emit("deleteCurrentUser", this.userId)
      //     }
      //   })
      //   .catch(error => {
      //     this.reasonErrors = error.response.data.errors.reason
      //       ? error.response.data.errors.reason[0]
      //       : null
      //     this.descriptionErrors = error.response.data.errors.description
      //       ? error.response.data.errors.description[0]
      //       : null
      //   })
      //   .finally(() => (this.isLoading = false))
    }
  }
}
</script>
